
.megamenu {

  position: relative;

  > li {
    position: static;
  }

  .dropdown-menu {
    top: auto;
    right: 0;
    box-shadow: 3px 2px 4px rgba(148, 148, 148, 0.68);
  }

  .dropdown-content {
    padding: 1em;
  }
}