

.ce-right > div {
  @include pull-right;
}

.ce-left > div {
  /* macht in IE 11 Probleme und bei Videos */
  //@include pull-left;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.ce-textpic {
  overflow: hidden;
}

.ce-uploads-description {
  color: #888;
  margin-left: 1.5em;
  display: block;
}

.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-mp3:before {
  content: "\f1c7";
}
.fa-file-png:before, .fa-file-jpg:before, .fa-file-jpeg:before, .fa-file-gif:before, .fa-file-tiff:before {
  content: "\f1c5";
}
.fa-file-mp4:before {
  content: "\f1c8";
}
.ce-uploads-fileicon {
  margin-right: 0.5em;
}