

.bs-example {
  position: relative;
  padding: 45px 15px 15px;
  margin: 0 -15px 15px;
  border-color: #e5e5e5 #eee #eee;
  border-style: solid;
  border-width: 1px 0;
  -webkit-box-shadow: inset 0 3px 6px rgba(0,0,0,.05);
  box-shadow: inset 0 3px 6px rgba(0,0,0,.05);
}

.bs-example {
  margin-right: 0;
  margin-left: 0;
  background-color: #fff;
  border-color: #ddd;
  border-width: 1px;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bs-example:after {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  font-weight: 700;
  color: #959595;
  text-transform: uppercase;
  letter-spacing: 1px;
  content: "Example";
}