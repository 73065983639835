.w-100 {
  width: 100% !important
}

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important
}

.m-a-0 {
  margin: 0 0 !important
}

.m-t-0 {
  margin-top: 0 !important
}

.m-r-0 {
  margin-right: 0 !important
}

.m-b-0 {
  margin-bottom: 0 !important
}

.m-l-0 {
  margin-left: 0 !important
}

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.m-a-1 {
  margin: 1rem 1rem !important
}

.m-t-1 {
  margin-top: 1rem !important
}

.m-r-1 {
  margin-right: 1rem !important
}

.m-b-1 {
  margin-bottom: 1rem !important
}

.m-l-1 {
  margin-left: 1rem !important
}

.m-x-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important
}

.m-y-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.m-a-2 {
  margin: 1.5rem 1.5rem !important
}

.m-t-2 {
  margin-top: 1.5rem !important
}

.m-r-2 {
  margin-right: 1.5rem !important
}

.m-b-2 {
  margin-bottom: 1.5rem !important
}

.m-l-2 {
  margin-left: 1.5rem !important
}

.m-x-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important
}

.m-y-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important
}

.m-a-3 {
  margin: 3rem 3rem !important
}

.m-t-3 {
  margin-top: 3rem !important
}

.m-r-3 {
  margin-right: 3rem !important
}

.m-b-3 {
  margin-bottom: 3rem !important
}

.m-l-3 {
  margin-left: 3rem !important
}

.m-x-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important
}

.m-y-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important
}

.p-a-0 {
  padding: 0 0 !important
}

.p-t-0 {
  padding-top: 0 !important
}

.p-r-0 {
  padding-right: 0 !important
}

.p-b-0 {
  padding-bottom: 0 !important
}

.p-l-0 {
  padding-left: 0 !important
}

.p-x-0 {
  padding-right: 0 !important;
  padding-left: 0 !important
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.p-a-1 {
  padding: 1rem 1rem !important
}

.p-t-1 {
  padding-top: 1rem !important
}

.p-r-1 {
  padding-right: 1rem !important
}

.p-b-1 {
  padding-bottom: 1rem !important
}

.p-l-1 {
  padding-left: 1rem !important
}

.p-x-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important
}

.p-y-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.p-a-2 {
  padding: 1.5rem 1.5rem !important
}

.p-t-2 {
  padding-top: 1.5rem !important
}

.p-r-2 {
  padding-right: 1.5rem !important
}

.p-b-2 {
  padding-bottom: 1.5rem !important
}

.p-l-2 {
  padding-left: 1.5rem !important
}

.p-x-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important
}

.p-y-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.p-a-3 {
  padding: 3rem 3rem !important
}

.p-t-3 {
  padding-top: 3rem !important
}

.p-r-3 {
  padding-right: 3rem !important
}

.p-b-3 {
  padding-bottom: 3rem !important
}

.p-l-3 {
  padding-left: 3rem !important
}

.p-x-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important
}

.p-y-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important
}

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.text-justify {
  text-align: justify !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-xs-left {
  text-align: left !important
}

.text-xs-right {
  text-align: right !important
}

.text-xs-center {
  text-align: center !important
}

@media (min-width:544px) {
  .text-sm-left {
    text-align: left !important
  }

  .text-sm-right {
    text-align: right !important
  }

  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width:768px) {
  .text-md-left {
    text-align: left !important
  }

  .text-md-right {
    text-align: right !important
  }

  .text-md-center {
    text-align: center !important
  }
}

@media (min-width:992px) {
  .text-lg-left {
    text-align: left !important
  }

  .text-lg-right {
    text-align: right !important
  }

  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width:1200px) {
  .text-xl-left {
    text-align: left !important
  }

  .text-xl-right {
    text-align: right !important
  }

  .text-xl-center {
    text-align: center !important
  }
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.font-weight-normal {
  font-weight: normal
}

.font-weight-bold {
  font-weight: bold
}

.font-italic {
  font-style: italic
}



@media (max-width:543px) {
  .hidden-xs-down {
    display: none !important
  }
}

@media (min-width:544px) {
  .hidden-sm-up {
    display: none !important
  }
}

@media (max-width:767px) {
  .hidden-sm-down {
    display: none !important
  }
}

@media (min-width:768px) {
  .hidden-md-up {
    display: none !important
  }
}

@media (max-width:991px) {
  .hidden-md-down {
    display: none !important
  }
}

@media (min-width:992px) {
  .hidden-lg-up {
    display: none !important
  }
}

@media (max-width:1199px) {
  .hidden-lg-down {
    display: none !important
  }
}

@media (min-width:1200px) {
  .hidden-xl-up {
    display: none !important
  }
}

