

.btn-group {
  div + div {
    //margin-left: -4px;
  }
}

.btn-group > div:first-child {
  > .btn {
    margin-left: 0;
  }

  &:not(:last-child):not(.dropdown-toggle) {
    > .btn {
      //@include border-right-radius(0);
    }
  }
}

.btn-group > div:last-child:not(:first-child) {
  .btn {
    //@include border-left-radius(0);
  }
}



.btn-group {
  > div {
    display: inline;
  }
}