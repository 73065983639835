

.align-left           { text-align: left; }
.align-right          { text-align: right; }
.align-center         { text-align: center; }
.align-justify        { text-align: justify; }

a.external-link, a[target="_blank"] {
  i:before        { content: "\f08e\00a0"; }
}

a.external-link-new-window, a[target="_blank"] {
  i:before        { content: "\f08e\00a0"; }
}
a.internal-link {}
a.internal-link-new-window {

}
a.download {}
a.mail, a[href^="javascript:linkTo_UnCryptMailto"] {
  i:before        { content: "\f003\00a0"; }
}
a.more-link {
  i:before        { content: "\f101\00a0"; }
}
a.button-link { }
a.btn { }


.style1 {
  color: darkred;
}


ul.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  li {

    a {
      color: #666;
      display: block;
      border-bottom: 1px solid #ECEBEB;
      padding: 3px 0;
    }
  }
}

a[data-toggle="tooltip"] {
  white-space: nowrap;
  &:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f29c";
    margin-left: 2px;
  }

}