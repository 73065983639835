.accordion {
  --bs-accordion-active-bg: transparent;

  .accordion-item {
    margin-bottom: 12px;
    border: none;
    .accordion-header {
      margin: 0;
      button {
        background: #fff;
        border: 2px solid #E3E5ED;
        border-radius: $accordion-border-radius;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .accordion-collapse {
      margin-top: 8px;
      border-radius: $accordion-border-radius;
      background: #fff;
      box-shadow: 0px 15.74px 37.78px 0px rgba(22, 42, 107, 0.10);
    }
  }
}
