// RMV CI Colors
$color-rmv-blue:                #162A6B;
$color-rmv-blue-gradient:       linear-gradient(135deg, #243C8B 0%, #162A6B 100%);
$color-rmv-green:               #068471;
$color-rmv-lightgreen:          #bfe0d3;
$color-rmv-darkorange:          #CC4B00;
$color-rmv-gray:                #666666;
$color-rmv-middlegray:          #cccccc;
$color-rmv-lightgray1:          #e2eaf0;
$color-rmv-lightgray2:          #f0f4f7;
$color-darkside-darkgray:       #666666;
$color-darkside-lightgray:      #f3f2f0;
$color-lno-mtv-red:             #E21219;
$color-lno-vgo-red:             #b5123e;
$color-lno-vht-red:             #e70000;
$color-lno-rtv-green:           #178737;
$color-lno-lnvg-red:            #CF0038;
$color-lno-vldw-blue:           #10137E;
$color-lno-eswe-blue:           #3B7BB0;
$color-lno-vgf-green:           #00838A;
$color-lno-vgf-yellow:          #fdc82f;
$color-lno-wetzlar-red:         #CC0000;
$color-lno-main-kinzig-blue:    #003889;

$font-size-base:                1rem;
$btn-font-weight:               700;

$accordion-border-radius:       12px;
$accordion-bg:                  transparent;
$accordion-button-color:        $color-rmv-blue;