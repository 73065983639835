@mixin btn-rmv() {
}

.btn {
  span + i {
    margin-left: 5px;
  }
  i ~ span {
    margin-left: 5px;
  }
}

.btn-primary, .btn-rmv-primary {
  @include btn-rmv();
  background: linear-gradient(135deg, #243C8B 0%, #162A6B 100%);
  &:hover, &:active {
  }
}

.btn-secondary, .btn-rmv-secondary {
  @include btn-rmv();
  //background-color: $color-rmv-gray;
  &:hover, &:active {
    //background-color: $color-rmv-gray;
  }
}

.btn-outline-secondary {
  --bs-btn-color: #{$color-rmv-blue};
  --bs-btn-border-color: #E3E5ED;
  --bs-btn-hover-color: #{$color-rmv-blue};
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #E3E5ED;
  &:hover, &:active {
  }
}

/* Make buttons in the left sidebar 100% width */
.col-menu a.btn {
  display: block;
}