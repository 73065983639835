.tx-kesearch-pi1 {
  font-family: arial,sans-serif;
  font-size: 14px;
  .clearer {
    display: block;
    height: 1px;
    line-height: 1px;
    clear: both;
  }
  form {
    border: 0 none;
    margin: 0 0 20px 0;
    fieldset {
      margin: 0;
      padding: 0;
    }
  }
  .kesearch_searchbox {
    border: none;
  }
}

/* Clearer */

/* formElements */

/* Buttons */

a.kesGreyButt {
  padding: 5px 10px;
  margin: 0 10px 10px 0;
  display: block;
  cursor: pointer;
  min-width: 70px;
  max-width: 140px;
  -moz-user-select: none;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(center top, #f6f6f6, #f0f0f0);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  color: #444;
  cursor: default;
  font-family: arial,sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

span {
  &.resetbutt a, &.submitbutt input {
    padding: 5px 10px;
    margin: 0 10px 10px 0;
    display: block;
    cursor: pointer;
    min-width: 70px;
    max-width: 140px;
    -moz-user-select: none;
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(center top, #f6f6f6, #f0f0f0);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    color: #444;
    cursor: default;
    font-family: arial,sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
}

input.kesGreyButt {
  padding: 5px 10px;
  margin: 0 10px 10px 0;
  display: block;
  cursor: pointer;
  min-width: 70px;
  max-width: 140px;
  -moz-user-select: none;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(center top, #f6f6f6, #f0f0f0);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  color: #444;
  cursor: default;
  font-family: arial,sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

span {
  &.kesGreyButt {
    padding: 5px 10px;
    margin: 0 10px 10px 0;
    display: block;
    cursor: pointer;
    min-width: 70px;
    max-width: 140px;
    -moz-user-select: none;
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(center top, #f6f6f6, #f0f0f0);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    color: #444;
    cursor: default;
    font-family: arial,sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
  &.submitbutt input {}
}

input.kesGreyButt, span.kesGreyButt {}

/* fix firefox button size (line-height is not working in firefox submit button)*/

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

li.checkboxButtonSubmit span, span.submitbutt input, input.kesGreyButt[type="submit"] {
  min-width: 169px;
  max-width: 220px;
}

span {
  &.resetbutt {
    float: left;
    a:hover {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      background: #d1d1d1;
      background-color: #f8f8f8;
      background-image: -moz-linear-gradient(center top, #f8f8f8, #f1f1f1);
      border: 1px solid #c6c6c6;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      color: #222;
    }
  }
  &.submitbutt input:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    background: #d1d1d1;
    background-color: #f8f8f8;
    background-image: -moz-linear-gradient(center top, #f8f8f8, #f1f1f1);
    border: 1px solid #c6c6c6;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    color: #222;
  }
}

input.kesGreyButt:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  background: #d1d1d1;
  background-color: #f8f8f8;
  background-image: -moz-linear-gradient(center top, #f8f8f8, #f1f1f1);
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #222;
}

span.kesGreyButt {
  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    background: #d1d1d1;
    background-color: #f8f8f8;
    background-image: -moz-linear-gradient(center top, #f8f8f8, #f1f1f1);
    border: 1px solid #c6c6c6;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    color: #222;
  }
  a {
    text-decoration: none;
    color: #444;
    text-shadow: 0px 1px 0px #ffffff;
    &:hover {
      text-decoration: none;
      color: #000;
      text-shadow: 0px -1px 0px #ffffff;
    }
  }
}

/* buttFixes */

#kesearch_filters .list li {
  &.checkboxButton {
    text-decoration: none !important;
    padding-bottom: 5px;
  }
  &.checkboxButtonSubmit {
    text-decoration: none !important;
    padding-bottom: 5px;
    margin-bottom: 1px;
    padding-bottom: 0;
  }
}

/* SearchBox */

div.kesearchbox {
  position: relative;
  height: 35px;
  margin: 0 0 10px 0;
}

#ke_search_sword {
  position: absolute;
  top: 1px;
  width: 220px;
  height: auto;
  padding: 8px 40px 7px 9px;
  margin: 0;
  display: inline;
}

#kesearch_submit {
  position: absolute;
  top: 3px;
  left: 237px;
  height: 28px;
  width: 28px;
  border: 0 none;
  padding: 0;
  &:focus, &:hover {
    box-shadow: 0 0 0 white;
    border: 0 none;
    cursor: pointer;
  }
}

/* Filters */

#kesearch_filters {
  div.textlinks {
    margin: 0 0 20px 0;
  }
  select {
    min-width: 180px;
    max-width: 270px;
    margin: 0 0 10px 0;
  }
  .list {
    margin-bottom: 10px;
    .head {
      display: block;
      border: 1px solid #CFCFCF;
      padding: 5px;
    }
    ul {
      padding: 10px;
      margin: 0;
      border: 1px solid #cfcfcf;
      border-top: 0 none;
      list-style-type: none;
      &.expanded {
        display: block;
      }
      &.closed {
        display: none;
      }
    }
    li {
      display: block;
      list-style-type: none;
      margin: 0 0 10px 0;
      line-height: 16px;
      color: #444;
      a {
        color: #444;
      }
      &:hover, a:hover {
        text-decoration: none;
        color: #444;
        cursor: pointer;
      }
      &.selected {
        font-weight: bold;
      }
      &.parent0, &.level0 {
        margin-left: 14px;
      }
      &.parent1, &.level1 {
        margin-left: 21px;
      }
      &.parent2, &.level2 {
        margin-left: 28px;
      }
      &.parent3, &.level3 {
        margin-left: 35px;
      }
      &.parent0, &.parent1, &.parent2, &.parent3 {
        font-weight: bold;
        list-style-position: outside;
      }
    }
    ul.checkboxList {
      padding-bottom: 1px;
    }
    li {
      &.optionCheckBox {
        float: left;
        width: 200px;
        text-decoration: none;
        margin-bottom: 4px;
        input[type="checkbox"] {
          float: left;
          margin: 0 5px 0 0;
        }
      }
      &.clearer {
        font-size: 0;
        line-height: 0;
        float: none;
        margin: 0 !important;
      }
    }
  }
  label {
    display: block;
    font-weight: bold;
    color: #353535;
    margin: 0 0 4px 0;
  }
  .list li {
    &.region {
      font-weight: bold;
      margin-top: 5px;
    }
    &.noclick {
      cursor: default !important;
    }
    &.country {
      margin-left: 7px;
    }
  }
  .small {
    display: block;
    height: 65px;
    max-height: 65px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .larger {
    display: block;
    height: 130px;
    max-height: 130px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/* Checkboxes */

/* SPECIAL CSS CLASS */

/* multiselectPage */

.tx-kesearch-pi1 .multiselect {
  .multi-option {
    float: left;
    width: 200px;
    margin: 0 0 5px 0;
    input[type="checkbox"] {
      float: left;
      margin: 0 5px 0 0;
    }
  }
  label {
    font-weight: normal;
    padding: 0 10px 0 0;
    float: left;
  }
  .multiselectButtons {
    padding: 10px 0 0 0;
  }
  .last {
    /* ???? */
  }
}

/* Results */

#kesearch_num_results {
  color: #898989;
  max-width: 150px;
  float: left;
}

#kesearch_results {
  list-style-type: none;
  padding-left: 0;
  .result-list-item {
    clear: both;
    margin: 20px 0 30px 0;
    padding: 0;

    .result-number, .result-title {
      font-size: 18px;

      a {
        color: #000;
        text-decoration: underline;
      }
    }

    .result-title .fa {
      margin: 0px 5px;
    }

    @media screen and (min-width: $screen-md-min) {
      .teaser-image {
        float: left;
        margin-right: 10px;
      }
    }

    .result-teaser {
      color: #707070;
    }

    &.result-list-item-type-file {
      .add-info {
        margin-bottom: 10px;
      }
    }
  }
  clear: both;
  margin-top: 20px;
}

.tx-kesearch-pi1 {
  #kesearch_results {
    .result-list-item-type-page, .result-list-item-type-tt_news {}
    .teaser_icon img {
      float: left;
      margin-top: 2px;
      margin-right: 5px;
    }
    .result-list-item {
      .hit {
        color: $color-rmv-green;
        font-weight: bold;
      }
      .add-info {
        .breadcrumb-section {
          min-height: 0;
          ol {
            padding-top: 0;
            li, li:before {
              color: #898989;
              font-weight: normal;
            }
          }
        }
      }
      .scoreBar {
        border: 1px solid #D3D3D3;
        display: block;
        float: right;
        height: 10px;
        width: 48px;
        .score {
          background-color: #D3D3D3;
          border: 1px solid #898989;
          display: block;
          height: 10px;
          margin: -1px 0 0 -1px;
        }
      }
    }
  }
  #kesearch_query_time {
    font-style: italic;
    text-align: center;
    font-size: 80%;
  }
  .searchbox {
    margin-bottom: 5px;
    input {
      margin-left: 0;
      width: 145px;
      &.submit {
        width: 18px;
        height: 18px;
        border: none;
        background: none;
        vertical-align: middle;
      }
    }
  }
  form {
    &.static .searchbox input.submit {
      margin-top: -16px;
    }
    &.ajax .searchbox input.submit {
      margin-top: -4px;
    }
  }
  .searchbox .search_input {
    float: left;
    margin-right: 5px;
  }
  .general-message {
    padding: 5px;
    .image {
      float: left;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

/* Query time */

/* Searchbox */

/* General Message */

/* Pagebrowser */

.kesearch_pagebrowser ul {
  display: inline;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  li {
    list-style-type: none;
    display: inline;
    margin: 0;
  }
}

#kesearch_pagebrowser_top, #kesearch_pagebrowser_bottom {
  word-wrap: break-word;
  font-size: 12px;
  color: #707070;
}

#kesearch_pagebrowser_top .pages_total, #kesearch_pagebrowser_bottom .pages_total {
  padding: 8px 0;
  background: #fff;
  margin-bottom: 15px;
}

#kesearch_pagebrowser_top .pages_total {
  border-bottom: 1px dashed #707070;
}

#kesearch_pagebrowser_bottom .pages_total {
  border-top: 1px dashed #707070;
}

#kesearch_pagebrowser_top div.result_txt, #kesearch_pagebrowser_bottom div.result_txt {
  margin: 0 0 5px 0;
}

.tx-kesearch-pi1 {
  #kesearch_ordering {
    span {
      &.down {
        display: inline-block;
        width: 0;
        height: 0;
        border-color: #FF0000 transparent;
        border-style: solid solid none;
        border-width: 4px 4px 0;
      }
      &.up {
        display: inline-block;
        width: 0;
        height: 0;
        border-color: transparent transparent #FF0000;
        border-style: none solid solid;
        border-width: 0 4px 4px;
      }
    }
    div.ordering {
      float: right;
      max-width: 300px;
    }
    ul {
      display: inline-block;
      height: 20px;
      list-style-type: none;
      margin: 0 0 10px;
      padding: 0;
      li {
        float: left;
        margin-right: 10px;
      }
    }
    div.clearer {
      clear: left;
    }
  }
}

/* Ordering */

/* needed for autocomplete */

ul.ui-autocomplete li a {
  font-size: 12px;
}
