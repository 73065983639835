@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 200;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kw461EN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kzm61EN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8KyK61EN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxm7FEN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxf7FEN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kw47FEN.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 900;
  src: url(#{$google-font-path}nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8KwR7FEN.ttf) format('truetype');
}
