

.panel-heading {
  margin-bottom: 0;
  a {
    text-align: left;
  }
}

.panel-collapse {
  padding: 0;
}