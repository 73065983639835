//@import '../../../node_modules/bootstrap/scss/functions';
//@import '../../../node_modules/bootstrap/scss/mixins';

//@import 'variables';
//@import '../../../node_modules/bootstrap/scss/bootstrap';


@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "variables";

// compatibility stuff needed during upgrade from bootstrap 3 to bootstrap 5
@import 'bootstrap3_compatibility/bootstrap3_compatibility';

@import '../../../node_modules/bootstrap/scss/bootstrap';

// Bootstrap stuff from bootstrap v4, to make html templates compatible with v4 even if we use v3
@import "bootstrap_upwards/viewhelper";
@import "bootstrap_upwards/pulls";
@import "bootstrap_upwards/defaults";
@import "media";
@import "type";
@import "clearfix";
@import "button-group";
@import "alert";
@import "buttons";
@import "card";
@import "bsexample";
@import "panel";
@import "tabs";
@import "megamenu";
@import "input-group";
@import "images";
@import "helpers";
@import "accordion";

@import "extensions/news";
@import "extensions/fluid_styled_content";
@import "extensions/ke_search";
@import "extensions/ikanos_regiomap";

// Animated hamburger icons
@import "hamburgers/hamburgers";

@import "fontawesome/font-awesome";
@import "fonts/source-sans-pro";

//@import "datepicker/bootstrap-datetimepicker.scss";

:root {
  --ci-primary: #{$color-rmv-green};
  --ci-primary-gradient: #{$color-rmv-blue-gradient};
}

.navbar-nav {
  --bs-navbar-active-color: #{$color-rmv-blue};
  --bs-nav-link-hover-color: #{$color-rmv-blue};
  --bs-dropdown-link-hover-bg: #fff;
}

.btn {
  margin: 11px 0;
}

.btn-primary {
  --bs-btn-border-color: transparent;
}

html {
  min-height: 100%;
}

.quicklinks {
  position: relative;
  z-index: 999;
  a {
    position: absolute;
    top: -100px;
    left: 10px;
    padding: 10px;
    font-size: 1.5em;
    color: #fff;
    background: #666;
    &:focus {
      top: 10px;
    }
  }
}

body.frontend {
  font-family: 'Source Sans Pro', sans-serif;

  background: #F6F7FA;
  .container, .container-fluid {
    background-clip: content-box;
    padding: 0px; // wish of rmv: remove the padding from container, as we have an inner blue background with padding
  }
  // Position radios and checkboxes better
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 5px;
  }
  // temporarly hides flexifliter
  &#page_2777,
  &#page_3225 {
    #filterByFlexifilter {
      display: none;
    }
  }
  // temporarly hides linkcollection on regiofilter
  .tx-ikanos-rgfilter .LinkCollection {
    display: none;
  }
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  a {
    text-decoration: none;
  }
}

.accordion-body {
  h3 {
    font-size: 17px;
  }
  h4 {
    font-size: 16px;
  }
}

picture img,
.ce-media img {
  border-radius: 16px;
}

img.img-fluid {
  max-width: 100%;
}

figure {
  margin: 0 0 15px 0px;
}

figcaption {
  margin-top: 5px;
  color: #696866;
}

p.legend {
  font-size: 11px;
  line-height: 11px;
  color: #666;
}

.multi-column,
.has-fullwidth-image,
.inside-full-height,
.col-menu {
  figcaption {
    display: none;
  }
}

.clearfix {
  height: 0px;
  line-height: 0px;
  font-size: 0px;
}

#page:not(.tpl-startpage) .box.has-headline > .badge {
  display: none;
}

// deprecated, we've provided better classes (check _buttons.scss)
.btn.btn-primary {
  @include btn-rmv();
  &.darkorange {
    @extend .btn-rmv-primary;
  }
  &.gray {
    @extend .btn-rmv-secondary;
  }
}

.p-without-margin p {
  margin: 0;
}

// bootstrap forms
.form-group a:not(.btn) {
  text-decoration: underline;
  &:hover {
    color: $color-rmv-green;
  }
}
.has-error {
  a {
    color: #a94442;
  }
  .btn-datepicker-popup {
    border-color: transparent;
    background-color: transparent;
  }
}

// Icons inside input-fields
.form-inner-addon {
  position: relative;
  .fa {
    position: absolute;
    padding: 10px;
    pointer-events: none;
  }
  &.left-addon {
    .fa {
      left: 0px;
    }
    input {
      padding-left: 30px;
    }
  }
  &.right-addon {
    .fa {
      right: 0px;
    }
    input {
      padding-right: 30px;
    }
  }
}

// Image beside text
.ce-textpic.ce-intext.ce-left {
  margin-bottom: 10px;
  .ce-media {
    float: left;
    margin-right: 22px;
  }
}

@media screen and (min-width: $screen-sm-min) {
  // Bootstrap responsive columns with same height
  .row-height {
    display: table;
    table-layout: fixed;
    // hack for chrome 63+
    height: 1px;
    width: 100%;
    .col-height {
      display: table-cell;
      float: none;
      height: 100%;
      vertical-align: top;
      .inside-half-height {
        height: 50%;
        &:first-child {
          padding-bottom: 11px;
        }
        &:last-child {
          padding-top: 11px;
        }
      }
    }
    &.has-box {
      margin-bottom: 22px;
      .col-height:first-of-type {
        padding-right: 10px;
      }
      .col-height:last-of-type {
        padding-left: 10px;
      }
      .inside-full-height {
        height:100%;
        > div:first-of-type {
          height: 100%;
        }
        > div.inside-half-height {
          height: 50%;
        }
      }
      .box {
        height: 100%;
        margin-bottom: 0;
        overflow: hidden;
        h4 {
          min-width: 80%;
        }
      }
    }
  }
}

// fix for bootstrap dropdown-menus
.dropup .open {
  display: block;
}

// Modal Boxes
// vertical center modal-boxes >>
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
// << vertical center modal-boxes

.modal-content {
  button.close {
    margin: 10px;
  }
  .box {
    box-shadow: 0 0 0 0;
    margin-bottom: 0;
  }
}

// textmedia
.ce-textpic {
  .ce-bodytext {
    a {
      text-decoration: underline;
      &.external-link, &.external-link-new-window {
        i {
          margin-left: 5px;
        }
      }
      &:hover {
        color: $color-rmv-green;
      }
    }
  }
}

.ce-bodytext ol,
.ce-bodytext ul,
ul.ce-bullets,
ol.ce-bullets {
  padding-left: 30px;
  li {
    padding: 5px 0px;
  }
}

// alerts
.alert > .fa {
  float: left;
  font-size: 14px;
  margin-right: 10px;
}

table {
  &.table-with-icon {
    tr > td:first-child {
      width: 25px;

      img {
        margin-top: 1rem;
      }
    }
  }
  &.table-bordered {
    > thead > tr > th,
    > thead > tr > td {
      border-bottom-width: 1px;
    }
    border-left: none !important;
    border-right: none !important;
    th, td {
      border-left: none !important;;
      border-right: none !important;;
    }
  }
}

.box {
  margin-bottom: 22px;
  padding: 22px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 15.74px 37.78px 0px rgba(22, 42, 107, 0.10);
  &.rmv-lightgray2 {
    position: relative;
    background: $color-rmv-lightgray2;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.has-headline {
    > h2 {
      display: inline-block;
      margin: 0;
      padding: 6px 12px;

      font-size: 14px;
      font-weight: 700;
      color: #fff;
      border-radius: 8px;
      border: 1px solid var(--COLORS-White, #FFF);
      background:linear-gradient(90deg, #009C84 0%, #017E6B 100%);
      box-shadow: 0px 4px 16px 0px rgba(0, 134, 113, 0.20);
    }

    .content {
      margin-top: 15px;
      h2, h3 {
        margin-top: 0;
      }
    }

    &.has-headline {
      > .headline {
        > p {
          font-size: 14px;
          font-weight: bold;
          margin: 0 0 7px;
        }
        > h2, > a > h2 {
          margin: 0;
        }
      }
      > figure figcaption, > a > figure figcaption, {
        padding-left: 22px;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      &.multi-column:not(.image-apart-text){
        .column-1 {
          margin-bottom: 35px;
        }
      }
    }
  }
  &.has-fullwidth-image {
    padding: 0;
    .content {
      padding: 16px 22px 22px 22px;
      h2, h2 a, h2 a:hover {
        font-size: $font-size-base;
        font-weight: bold;
        line-height: $line-height-base;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  // Image is in one column, text in the other
  &.image-apart-text {
    h1:first-child,
    h2:first-child {
      margin-top: 0;
    }
    @media screen and (min-width: $screen-sm-min) {
      .ce-textpic.ce-above .ce-media {
        margin-bottom: 0px;
      }
    }
  }
  .ce-textpic.ce-above .ce-media {
    margin-bottom: 15px;
  }
}

.lazy {
  display: none;
}

// Back-to-top link
.back-to-top {
  display:none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 15px;
  height: auto;
  line-height: 1.33333;
  @media screen and (max-width: $screen-lg-min) {
    display: none !important;
  }
}

// bootstrap datepicker
.btn-datepicker-popup {
  padding: 0 0 0 10px;
  background: 0;
  border: none;
  font-size: 20px;
  color: $color-rmv-blue;
}

.ServiceBar {
  color: $color-rmv-gray;
  line-height: 18px;
  .icon-phone {
    margin-right: 10px;
  }
  .phone {
    display: block;
    font-size: 16px;
  }
  .servicetimes {
    display: block;
    font-size: 10px;
    line-height: 10px;
  }
  div[class*="social-icon"] {
    margin-left: 20px;
  }
  div[class*="social-icon"] ~ div[class*="social-icon"] {
    margin-left: 7px;
  }
  @media screen and (max-width: $screen-sm-max) {
    .social-icon.icon-facebook {
      clear: both;
      margin-top: 10px;
      margin-left: 36px;
    }
    .social-icon.icon-twitter {
      margin-top: 10px;
    }
    .social-icon.icon-instagram {
      margin-top: 10px;
    }
    .social-icon.icon-linkedin {
      margin-top: 10px;
    }
  }
  .icon-instagram img {
    width: auto;
    max-height: 23px;
  }
  .icon-linkedin img {
    width: auto;
    max-height: 23px;
  }
  .icon-youtube img {
    width: auto;
    max-height: 23px;
  }
}

.panel {
  margin-bottom: 5px;
  .panel-heading {
    a {
      display: block;
      padding: 10px 15px;
      background-clip: padding-box;
      font-weight: bold;
      font-size: 14px;
      color: #fff;
      background: $color-rmv-green;
      i.fa {
        margin-right: 10px;
        float: left;
        color: #fff;
        font-size: 21px;
        &:before {
          content: "\f13a";
        }
      }
      &:hover, &:focus {
        text-decoration: none;
      }
      &.collapsed {
        color: $color-rmv-blue;
        background: #fff;
        i.fa {
          color: $color-rmv-blue;
          &:before {
            content: "\f138";

          }
        }
      }
    }
  }
}

/* RoutePlanner */
$route-planner-height: 295px;
$route-planner-bg-color: #edeeef;
$route-planner-border-color: #d9dadb;
/* fix wrong positioning for hacons autocomplete-flyout */
#suggestion {
  margin-top: 15px !important;
}
/* class relaunch-layout can be removed as soon as NextDeparture.html and SingleInquiry.html fits the new template */
.RoutePlanner.relaunch-layout {

  @media screen and (max-width: $screen-sm-max) {
    label.control-label {
      text-align: left;
    }
  }

  /* accordion only on mobile devices */
  @media screen and (min-width: $screen-sm-min) {
    #accordion-routeplanner {
      .accordion-header {
        display: none;
      }
      .accordion-item {
        margin: 0;
        border: none;
      }
      .accordion-collapse {
        display: block;
        height: auto;
        .accordion-body {
          padding: 0;
          border: none;
        }

      }
    }
  }

  margin: 22px 11px;

  #timetable-date, #timetable-time {
    padding-right: 5px;
  }

  @media screen and (min-width: $screen-lg-min) {
    margin: 22px 0 22px 11px;
  }

  @media screen and (min-width: $screen-sm-min) {
    height: $route-planner-height;
  }

  .btn {
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  }

  .section-group {
    @media screen and (min-width: $screen-sm-min) {
      height: $route-planner-height - 32px; /* parent height - .toolbox height) */
    }
    .section {
      padding: 11px;
      display: none;
      &.on {
        display: block;
      }
      .form-field-date {
        @media screen and (max-width: $screen-xs-max) {
          margin-top: 15px;
          margin-bottom: 15px;
        }
        @media screen and (min-width: $screen-sm-min) {
          padding-left: 0;
          label {
            padding-right: 0;
          }
          > div > div[class*="col-"] {
            padding-right: 0;
          }
        }
      }
      select[name="timesel"] {
        padding-left: 8px;
      }
      a {
        text-decoration: underline;
        &:hover {
          color: $color-rmv-green;
        }
      }
    }
  }
  .toolbox {
    font-size: 14px;

    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      flex-grow: 1;
      a {
        display: block;
        line-height: 30px;
        font-weight: bold;
        color: $color-rmv-blue;
        border-bottom: 5px solid $state-info-border;
        /*
          Regarding the tabindex="-1" ref:
          https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press
          https://www.kizu.ru/keyboard-only-focus/#x
        */
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          &:hover, &:focus, &:active {
            text-decoration: none;
            outline: none !important;
          }
        }
        &.on {
          color: $color-rmv-green;
          border-bottom: 5px solid $color-rmv-green;
        }
      }
      &:first-of-type a {
        margin-left: 10px;
      }
      &:last-of-type a {
        margin-right: 10px;
      }

    }
  }
}

/* the whole block can be removed as soon as NextDeparture.html and SingleInquiry.html fits the new template */
.RoutePlanner.deprecated-layout {
  * {
    margin: 0px; padding: 0px;
  }
  .btn {
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  }
  ul {
    list-style: outside none none;
    li {
      margin-bottom: 9px;
      &.last {
        position: absolute;
        margin: 0;
        width: 100%;
        left: 0;
        bottom: 10px;
        padding: 0 33px;
        a {
          display: inline-block;
          position: relative;
          top: 0;
          left: -6px;
          padding: 3px 6px;
          line-height: 19px;
          color: $color-rmv-blue;
          &:hover {
            text-decoration: none;
            color: #fff;
            background: #4e91cd;
            .title {
              text-decoration: none;
            }
          }
          .arrow {
            float: left;
            display: none;
            text-decoration: none;
          }
          .title {
            display: inline-block;
            text-decoration: underline;
          }
        }
        @media screen and (max-width: $screen-md-max) {
          position: relative;
          bottom: 4px;
          padding: 0;
          .btn {
            display: block;
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
  input.small {
    width: 40px;
    margin-right: 5px;
  }
  #suggestion span[style="float:right"] {
    display: none;
  }
  .toolbox {
    display: inline-block;
    li {
      list-style: outside none none;
      float: left;
      display: inline-block;
      position: relative;
      &#connection {
        z-index: 2;
        a {
          padding: 0 35px 0px 25px;
        }
      }
      &#departure {
        left: -30px;
        z-index: 1;
        a {
          padding: 0px 35px 0px 45px;
        }
      }
      a {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        font-weight: bold;
        color: $color-rmv-blue;
        background: url('../Images/routeplanner_tab_inactive.png') right top no-repeat;
        &:hover, &:focus, &:active {
          text-decoration: none;
        }
        &.on {
          color: #fff;
          background: url('../Images/routeplanner_tab_active.png') right top no-repeat;
        }
      }
    }
  }
  .section {
    padding: 10px 22px;
    display: none;
    &.on {
      display: block;
    }
    .label-wrap {
      width: 15%;
      padding: 0px 9px 0px 0px;
      line-height: 20px;
      float: left;
      text-align: right;
      label {
        font-weight: normal;
        color: $color-rmv-blue;
      }
    }
    input {
      height: 20px;
      padding: 2px 5px;
      border: 1px solid $color-rmv-middlegray;
      &.HafasSuggest {
        width: 85%;
      }
    }
    .radio-wrap {
      float: right;
      margin-right: 50px;
      label {
        padding: 0 5px;
        font-weight: normal;
        line-height: 13px;
        color: $color-rmv-blue;
      }
      input {
        margin: 0;
        height: auto;
      }
    }
    button {
      float: right;
    }
  }
  #section-2 li.first {
    margin-top: 27px;
  }
  &.SingleInquiry {
    background: $color-rmv-lightgray2;
    .radio-wrap {
      margin-right: 0;
    }
    .section {
      padding: 10px 22px 10px 0px
    }
    a {
      margin-left: -6px;
      padding: 3px 6px;
      color: #000;
      .title {
        text-decoration: underline;
      }
      &:active, &:hover {
        color: #fff;
        background: $color-rmv-green;
        .title {
          text-decoration: none;
        }
      }
    }
  }
  &.NextDeparture {
    background: $color-rmv-lightgray2;
    .section {
      padding: 10px 22px 10px 0px;
    }
    p {
      margin-left: 15%;
    }
    a {
      margin-left: -6px;
      padding: 3px 6px;
      color: #000;
      .title {
        text-decoration: underline;
      }
      &:active, &:hover {
        color: #fff;
        background: $color-rmv-green;
        .title {
          text-decoration: none;
        }
      }
    }
  }
}

.frame-type-rmvtemplate_timetablemap iframe {
  border: none;
}

.frame-type-rmvtemplate_onlinebanner {
  height: 100%;
}

.OnlineBanner {
  height: 100%;
  border-radius: 20px;
  background: #e2eaf0;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: $screen-lg-min) {
    background-image: var(--background);
  }
  @media screen and (max-width: $screen-md-max) {
    background-image: none !important;
  }
  a.banner-link {
    display: block;
    height: 100%;
  }
}

.OnlineBanner.deprecated-layout {
  height: 125px;
  margin-bottom: 10px;
  padding: 15px 22px;
  background: #e2eaf0;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  a.banner-link {
    display: block;
    margin: -15px -22px;
    padding: 15px 22px;
    height: 125px;
  }
  h2 {
    font-size: 36px;
    color: $color-rmv-blue;
  }
  h3, p {
    color: $color-rmv-blue;
  }
  ul {
    padding: 0 0 0 15px;
    margin: 0;
    li {
      color: $color-rmv-blue;
      padding: 0px;
    }
  }
  .col-height {
    vertical-align: top;
  }
  .register-button {
    vertical-align: bottom;
    text-align: right;
    padding-bottom: 3px;
  }
}

.MeinRmvLogin {
  padding: 10px 22px;
  height: 68px;
  background: #e2eaf0;
  h2 {
    margin-bottom: 3px;
    font-size: 26px;
    color: $color-rmv-blue;
  }
  p {
    margin-bottom: 0;
    color: $color-rmv-blue;
  }
  .col-height {
    vertical-align: top;
  }
  .login-form {
    .form-group {
      margin: 0;
      input {
        padding: 5px 12px;
      }
      @media screen and (min-width: $screen-lg-min) {
        padding: 0px 5px 0px 0px;
      }
      label {
        font-weight: normal;
        color: $color-rmv-blue;
        @media screen and (max-width: $screen-md-max) {
          margin-top: 5px;
        }
      }
      .password-forgotten {
        float: right;
        a {
          color: $color-rmv-gray;
          text-decoration: underline;
        }
      }
      input {
        height: 25px;
      }
    }
    @media screen and (min-width: $screen-lg-min) {
      margin-left: 15px;
      [class*="col-"] {
        padding: 0;
      }
    }
  }
  #meinrmv-loginname {
    font-size: 14px;
    font-weight: bold;
    color: $color-rmv-blue;
  }
  .mobile-links {
    margin-top: 10px;
    float: none;
    a {
      display: inline-block;
      position: relative;
      top: 0;
      left: -6px;
      padding: 3px 6px;
      line-height: 19px;
      color: $color-rmv-blue;
      &:hover {
        text-decoration: none;
        color: #fff;
        background: #4e91cd;
        .title {
          text-decoration: none;
        }
      }
      .arrow {
        float: left;
        display: none;
        text-decoration: none;
      }
      .title {
        display: inline-block;
        padding-left: 5px;
        text-decoration: underline;
      }
    }
  }
  .login-button, .logout-button {
    vertical-align: bottom;
    text-align: right;
    @media screen and (max-width: $screen-md-max) {
      .btn {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  .logout-button {
    @media screen and (max-width: $screen-md-max) {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      padding: 0px 33px;
    }
  }
  .logged-in-links {
    a {
      display: inline-block;
      position: relative;
      top: 0;
      left: -6px;
      padding: 3px 6px;
      line-height: 19px;
      color: $color-rmv-blue;
      &:hover {
        text-decoration: none;
        color: #fff;
        background: #4e91cd;
        .title {
          text-decoration: none;
        }
      }
      .arrow {
        float: left;
        display: none;
        text-decoration: none;
      }
      .title {
        display: inline-block;
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: $screen-md-max) {
    height: 225px;
    .row-height {
      display: block;
      table-layout: auto;
      height: auto;
      width: auto;
    }
    .col-height {
      display: block;
      float: none;
      clear: both;
      height: auto;
    }
    p {
      display: none;
    }
  }
  @media screen and (max-width: $screen-xs-max) {
    height: auto;
  }
}

.TrafficNews {
  overflow: auto;
  &.teasermode {
    height: 360px;
    overflow: hidden;
  }
  &.hide-all-link {
    a.arrowlink {
      display: none;
    }
  }
}

.LazyGallery {
  .row {
    [class*="col-"] {
      margin-bottom: 22px;
      // clear floating of every 3rd element to fix height-differences within the same <div class="row">
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }
}

.LinkCollection {
  a {
    display: block;
    height: auto;
    font-size: 12px;
    padding: 3px 6px;
    margin-left: -6px;
    text-decoration: underline;
    color: #000;
    &.active, &:hover {
      color: $color-rmv-green;
    }
    .arrow {
      float: left;
      display: none;
    }
    .title {
      display: block;
    }
  }
}

header {
  border:1px solid transparent;
  #logo {
    position: relative;
    height: 89px;
    &:focus {
      /* box shadow of language-and-search:before would overlay the outline otherwise */
      outline-offset: 0 !important;
      z-index: 999;
    }
    a {
      &.lno-reset {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 180px;
        height: 100%;
        text-indent: -10000px;
        z-index: 2;
        @media screen and (max-width: $screen-md-max) {
          width: 105px;
        }
      }
      &.home {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        text-indent: -10000px;
        z-index: 1;
      }
      &:focus {
        /* box shadow of language-and-search:before would overlay the outline otherwise */
        outline-offset: 0 !important;
        z-index: 999;
      }
    }
    .hamburger-icon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      background: #fff;
    }
    .language-and-search {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding: 22px;
      z-index: 3;
      .btn-group:not(:last-child) {
        margin-right: 11px;
      }
      .btn-default {
        &:hover, &:focus {
          background: #fff;
        }
      }
      #availableLanguages {
        min-width: initial;
        &.open {
          display: block;
        }
        > li > a:hover {
          background-color: #f5f5f5;
        }
      }
      #dropdownPanelSearch {
        position: absolute;
        right: 0;
        width: 250px;
        /* remove slide effect */
        transition-duration: initial;
      }
    }
  }
  .nav-inline {
    .nav-item {
      display: inline-block;
    }
  }
  .navbar-user > .container > .row{
    margin-bottom: 10px;
  }
  .UserMenu {
    float: left;
    margin: 0px 30px 0px 50px;
    min-height: 1px;
    .nav > li > a {
      display: inline-block;
      height: auto;
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
      padding: 3px 6px;
      color: $color-rmv-gray;
      text-decoration: underline;
      &:hover, &:focus {
        color: $color-rmv-green;
      }
      &.active {
        color: $color-rmv-green;
      }
    }
    @media screen and (max-width: $screen-md-max) {
      margin: 5px 22px 0;
    }
  }
  .login-button {
    padding: 0px 22px;
    a {
      font-size: 16px;
      i.fa {
        vertical-align: middle;
        color: $color-rmv-blue;
      }
    }
  }
  .ServiceBar {
    float: left;
    @media screen and (max-width: $screen-md-max) {
      margin-left: 22px;
      float: none;
      clear: both;
    }
  }
  .LanguageMenu {
  }
  .searchBox {
    #ke_search_searchfield_submit {
      display: none;
    }
  }
  .navbar-language {
    height: 31px;
    border: 1px solid $color-rmv-middlegray;
    li.dropdown {
      height: 100%;
      &.open > a {
        background: #fff;
      }
      > a {
        padding: 6px 8px;
        font-size: 12px;
        float: left;
        color: #000;
        .word1 {
          font-weight: bold;
        }
        &:hover {
          text-decoration: none;
        }
      }
      i {
        padding: 6px 8px;
        font-size: 13px;
        background: #eee;
        height: 29px;
      }
      .dropdown-menu {
        margin: 0px 0px 0px -1px;
        padding: 0;
        min-width: 100%;
        a {
          padding: 3px 8px;
          font-size: 11px;
          line-height: normal;
          &:hover {
            color: #fff;
            background: $color-rmv-middlegray;
          }
          .word1 {
            font-weight: bold;
          }
        }
      }
    }
  }
  .header-content {
    position: relative;
    margin: 0 0 15px 0;
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0;
      > :not(.frame-type-rmvtemplate_onlinebanner) {
        display: none;
      }
    }
  }
  .navbar-wrap {
    position: relative;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .navbar-main {
    .dropdown .dropdown-menu.lno-menu {
      padding: 0 20px;
    }
    .navbar-nav > li > a:hover {
      box-shadow: 0px 20px 48px 0px rgba(22, 42, 107, 0.10);
    }
    border: none;
    .navbar-collapse {
      padding: 0;
    }
    li {
      > a {
        padding: 0px 15px;
        line-height: 50px;
        border-radius: 12px;
      }
      &:hover > a:not(.active) {
        background: #fff;
      }
    }
    // Hover (if no dropdown exists)
    li.no-dropdown:hover {
      // Comment in to have box shadow hover-effects on singlemeu items  (without dropdown)
      // -webkit-box-shadow: 2px 2px 31px 0px rgba(166,166,166,1);
      // -moz-box-shadow: 2px 2px 31px 0px rgba(166,166,166,1);
      //box-shadow: 2px 2px 31px 0px rgba(166,166,166,1);
      > a {
        text-decoration: none;
        // Comment in to have the offset like on the "tabs" of dropdowns
        //margin-top: 6px;
        //margin-bottom: -6px;
        //
        //border: none;
        //z-index: 1001;
        //background: #fff;
      }
      > a.active, > a.active:hover {
        text-decoration: none;
      }
    }
    a {
      color: $color-rmv-blue;
      height: 50px;
      font-size: 17px;
      font-weight: bold;
      text-decoration: none;
      &.active, &.active:hover, &.active:focus {
        background: linear-gradient(90deg, #009C84 0%, #017E6B 100%);
        box-shadow: 0px 4px 16px 0px rgba(0, 134, 113, 0.20);
        text-decoration: none;
      }
      &.nav-link.active {
        color: #fff;
      }
    }
    .dropdown {
      &.open {
        > a {
          z-index: 1001;
        }
      }
      .spacer {
        height: 20px;
      }
      // Submenu
      .dropdown-menu {
        min-width: 230px;
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 20px;
        box-shadow: 0px 20px 48px 0px rgba(22, 42, 107, 0.10);
        &.columns-2 {
          min-width: 480px;
        }
        &.columns-3 {
          min-width: 780px;
        }
        &.columns-4 {
          min-width: 1080px;
        }
        .column-2,
        .column-3,
        .column-4 {
          border-left: 1px solid $color-rmv-middlegray;
        }
        .row {
          margin: 22px 0px;
          [class*="col-"] {
            padding: 0px 22px;
          }
        }
        h3 {
          margin-bottom: 10px;
          font-size: 17px;
          font-weight: bold;
          color: $color-rmv-blue;
          white-space: nowrap;
        }
        img {
          margin-bottom: 10px;
          width: 100%;
          height: auto;
        }
        a:not(.btn) {
          display: inline-block;
          height: auto;
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          padding: 8px 16px;
          margin-left: -16px;
          &.active {
            border-radius: 8px;
            background: linear-gradient(135deg, #009C84 0%, #008671 100%);
            box-shadow: 0px 4px 16px 0px rgba(0, 134, 113, 0.20);

            text-decoration: none;
            &:hover {
              color: #fff;
            }
          }
          &:hover {
            text-decoration: underline;
            color: $color-rmv-blue;
          }
          .arrow {
            float: left;
            display: none;
          }
          .title {
            display: block;
          }
          &.cookie-reset {
            line-height: normal;
            padding: 0;
            margin: 0;
            &:hover {
              background: #fff;
            }
            h3 {
              margin: 0;
            }
          }
        }
        .multi-column-dropdown {
          list-style: none;
          padding: 0;
        }
      }
    }

  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      height: 60px;
    }
  }

  @media (max-width: $screen-xs-max) {
    .dropdown-menu.multi-column {
      min-width: 240px !important;
      overflow-x: hidden;
    }
  }

}

main {
  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    color: $color-rmv-blue;
  }

  h2 {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    color: $color-rmv-blue;
    a {
      color: $color-rmv-blue;
      margin-left: -6px;
      padding: 3px 6px;
      &.active, &:hover {
        color: $color-rmv-blue;
      }
    }
  }

  h3 {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    color: $color-rmv-blue;
    a {
      color: $color-rmv-blue;
      margin-left: -6px;
      padding: 3px 6px;
      &.active, &:hover {
        color: $color-rmv-blue;
      }
    }
  }

  h4, h5 {
    margin-top: 15px;
    margin-bottom: 15px;
    color: $color-rmv-blue;
    font-weight: bold;
  }

  .background {
    padding: 22px;
    &.service {
      margin-top: 22px;
      padding-top: 22px;
    }
    @media screen and (max-width: $screen-md-max) {
      padding: 22px;
      &.sidebar {
        margin-bottom: 15px;
      }
    }
    .spacer {
      float: left;
      width: 100vw;
      margin-top: 15px;
      margin-left: calc(-1 * ((100vw - 100%) / 2));/* align left edge to the left edge of the viewport */
      margin-bottom: 15px;
      .container {
        background: #fff;
        background-clip: content-box;
      }

    }
  }

  .nav-sidebar {
    h3 {
      margin: 0px;
      padding: 0px 0px 12px 0px;
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: $color-rmv-blue;
      border-bottom: 1px solid $color-rmv-middlegray;
    }
    // Hits the outer ul of the submenu-items
    ul.nav-stacked > li > ul.nav {
      margin-left: 0;
      margin-bottom: 35px;
    }
    a {
      padding: 3px 8px;
      color: #000;
      border-bottom: 1px solid $color-rmv-middlegray;
      &:focus {
        background: $color-rmv-lightgray2;
        text-decoration: none;
      }
      &:hover, &.current {
        color: #ffffff;
        background: $color-rmv-green;
        text-decoration: none;
      }
      .arrow {
        float: left;
        display: none;
      }
      .title {
        display: block;
      }
    }
    &.nav-accordion {
      h3 {
        display: none;
      }
      ul.nav.depth-1 > li:last-child > a {
        border-bottom: none;
      }
      // Hits the outer ul of the submenu-items
      ul.nav-stacked > li > ul.nav {
        margin-bottom: 0px;
      }
    }
  }

  a.help-link {
    display: inline-block;
    margin: 10px 22px;
  }

  .tx-ikanos-rgfilter {
    .select-all-none a {
      &.select-all {
        margin-right: 10px;
      }
      &:focus {
        outline: none !important;
      }
    }

    .above-panels-headline {
      font-size: 14px;
    }
    hr {
      margin-top: 22px;
      margin-bottom: 22px;
      border-top: 1px solid $color-rmv-middlegray;
    }
    b {
      margin: 5px 0px;
      display: inline-block;
    }
    .checkboxGroup hr {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .form-group {
      position: relative;
      margin-bottom: 0;
      .form-check-label {
        position: relative;
        display: block;
        margin-left: 20px;
        margin-bottom: 10px;
        font-weight: normal;
        z-index: 2;
        input {
          margin-left: -20px;
          margin-right: 5px;
        }
      }
      span.icon {
        position: absolute;
        top: 2px;
        right: 0;
        z-index: 1;
      }
    }
    .searchbar {
      .form-inner-addon {
        i {
          color: $color-rmv-gray;
        }
      }
    }
    .LinkCollection {
      margin-top: 15px;
    }
    &.map {
      .additionalCheckboxes {
        position: absolute;
        top: -200px;
        left: 80px;
        max-width: 150px;
      }
      .LinkCollection {
        position: absolute;
        top: -160px;
        left: 80px;
        max-width: 230px;
      }
    }
  }
  .tx-ikanos-lstips {
    .tipsToggler {
      position: relative;
      top: -22px;
      left: -22px;
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        position: relative;
        float: left;
        display: inline-block;
        &:first-child {
          z-index: 2;
          a {
            padding: 0 35px 0px 25px;
          }

        }
        &:nth-child(2) {
          left: -30px;
          z-index: 1;
          a {
            padding: 0 35px 0px 45px;
          }
        }
        a {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          font-weight: bold;
          color: $color-rmv-blue;
          background: url('../Images/tab_inactive.png') right top no-repeat;
          cursor: pointer;
          &:hover, &:focus {
            text-decoration: none;
          }
          &.active {
            color: #fff;
            background: url('../Images/tab_active.png') right top no-repeat;
          }
        }

      }
    }
    .form-group {
      &.f-right {
        position: absolute;
        right: 0;
      }
      .input-group.date {
        border-spacing: 10px 0px;
        input.form-control {
          width: 100px;
        }
      }
    }
    .input-group-addon {
      width: auto;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently */
      &.preamble {
        padding: 0;
        background: 0;
        border: none;
        .title {
          padding: 0px 15px 0px 0px;
          font-weight: bold;
          color: #000;
        }
      }
    }
    .resultList {
      hr {
        margin-top: 22px;
        margin-bottom: 22px;
        border-top: 1px solid $color-rmv-middlegray;
      }
      a {
        text-decoration: underline;
        color: #000;
        &:hover {
          color: #fff;
          background: $color-rmv-green;
        }
      }
      .category-icons {
        position: relative;
        top: -11px;
        float: right;
        img {
          margin-left: 5px;
        }
      }
      .date {
        margin-bottom: 5px;
        color: $color-rmv-gray;
      }
      .teaser {
        clear: both;
      }
    }
  }

  .tx-ikanos-contacts,
  .tx-ikanos-downloads {
    h3 {
      display: block;
      margin-top: 0;
      margin-bottom: 5px;
    }
    a {
      text-decoration: underline;
      &:hover, &:focus {
        color: $color-rmv-green;
      }
    }
  }

  .tx-ikanos-timetable {
    span.headline {
      h2 {
        float: left;
        color: #000;
      }
      img {
        float: left;
        margin: 7px 10px 7px 0px;
      }
    }
    .identifier {
      width: 12%;
    }
    .route {
      width: 36%;
    }
    .validity {
      width: 20%;
    }
    .type {
      width: 20%;
    }
    .download {
      width: 12%;
    }
    span.icon-temporary img {
      float: right;
    }
    tr.row-odd {
      background: #f9f9f9;
    }
    tr.row-first-of-group {
      td {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    tr.row-grouped {
      td {
        border: none;
        padding-top: 0;
      }
    }
    tr.row-grouped:not(.row-last-of-group) {
      td {
        padding-bottom: 0;
      }
    }
    tr.row-last-of-group {
      td {
        padding-top: 0;
      }
    }
    a {
      text-decoration: underline;
      span {
        text-decoration: none;
        margin-right: 5px;
      }
      &:hover {
        color: $color-rmv-green;
      }
    }
    ol {
      padding-left: 0;
      list-style-position: inside;
    }
  }
  .tx-ikanos-sweepstake {
    // form-fields with error
    .f3-form-error {
      color: #B94A48;
      background-color: #F2DEDE;
      border: 1px solid #EED3D7;
    }
    // validation messages
    .f3-errors-list {
      margin: 2px 0 3px;
      padding: 0;
      list-style-type: none;
      font-size: 0.9em;
      line-height: 0.9em;
      li {
        color: red;
      }
    }
    label p {
      a {
        text-decoration: underline;
        &.external-link, &.external-link-new-window {
          i {
            margin-left: 5px;
          }
        }
        &:hover {
          color: $color-rmv-green;
        }
      }
    }
  }
}

div#servicebar-widget {
  @media screen and (max-width: $screen-xs-max) {
    display: none;
  }
  background-color: #F6F7FA;
  border-radius: 10px;
  border: 2px solid  #E3E5ED;

  bottom: calc(20% - 65px);
  overflow: hidden;
  padding: 10px;
  position: fixed;
  right: 0;
  max-width: 50px;
  height: 50px;
  white-space: nowrap;
  z-index: 998;
  transition: max-width 0.8s ease;
  &.open {
    max-width: 500px;
  }
  button {
    border: 0;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0;
    z-index: 2;
    text-align: left;
    img {
      margin-left: 12px;
    }
    &:focus {
      outline: 0 !important;
    }

  }
  .servicebar-popup {
    margin-left: 40px;
    z-index: 1;
    span {
      display: block;
      color: #616E98;
      &.phone {
        margin-top: 0;
        font-size: 16px;
      }
      &.servicetimes {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}

footer {
  margin-top: 25px;
  .container {
    padding: 0px 11px !important;
  }
  .row {
    padding: 0px 11px;
    * .row {
      border: none;
      padding: 0;
    }
  }
  h2, h3 {
    margin-bottom: 15px;
    font-size: 13px;
    font-weight: bold;
    color: $color-rmv-blue;
    a {
      color: $color-rmv-blue;
    }
  }
  a, a.nav-link {
    display: inline-block;
    margin-left: -6px;
    padding: 3px 6px;
    color: $color-rmv-gray;
    &:hover {
      color: $color-rmv-green;
    }
    .arrow {
      float: left;
      display: none;
    }
    .title {
      display: block;
    }
  }
  .ServiceBar {
    a {
      margin: 0;
      padding: 0;
      line-height: normal;
      &:hover {
        background: #fff;
      }
    }
  }
  .ce-textpic {
    overflow: visible;
  }
  .ce-menu-0 {
    a {
      font-size: 13px;
      font-weight: bold;
      color: $color-rmv-blue;
    }
  }
  .copyright {
    color: $color-rmv-blue;
    font-weight: bold;
  }
  .footer-links {
    margin-bottom: 25px;
    a,
    a:hover,
    a:focus {
      color: $color-rmv-blue;
      font-weight: bold;
    }
    img {
      height: 16px;
    }
  }
}

// footer of the mm-menu
#mobile-footer {
  flex-wrap: wrap;
  .btn-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .btn-group:not(:last-child) {
    margin-right: 11px;
  }
  .btn {
    color: #000;
    padding: 6px;
  }
  .footer-links {
    border: 1px solid var(--mm-color-border);
    li {
      padding: 6px 12px;
      a {
        color: $color-rmv-blue;
      }
    }
  }
  #mobileDropdownPanelSearch {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 100%;
    width: 250px;
    margin-bottom: 8px;
    transition-duration: initial;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    .btn-search {
      border: 1px solid #ccc;
      border-left: 0;
    }
  }
}

// lno pages (everything under the lno startpage)
@mixin brand-home ($brand-color) {
  // lno landingpage(s) (but only if not viewed inside the rmv app >= v3
  &.home:not(.layout-inapp) {
    .box.has-headline .badge {
      background: linear-gradient(90deg, $brand-color 0%, $brand-color 100%)
    }
  }
}

// hide body if lno-cookie is set (javascript will make it visible after ajax content loaded - avoids
// that the original content is displayed for a second before the lno-content is shown)
body.frontend:not(.lno-none) {
  visibility: hidden;
}

body.lno-none {
  #logo {
    background: url('../Images/logo-rmv.svg') 15px 30px no-repeat;
    a.lno-reset {
      display: none;
    }
  }
  // cookie-reset button on "RMV vor Ort" dropdown
  header .navbar-main .dropdown .dropdown-menu .row.cookie-reset {
    display: none;
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background: url('../Images/logo-rmv-mobile.svg') 10px 10px no-repeat;
      background-size: auto 25px;
    }
  }
  .navbar-nav > li:first-of-type > a:after {
    display: none;
  }
}

body.lno-main-taunus {
  @include brand-home($color-lno-mtv-red);
  #logo {
    background: url('../Images/logo-lno-main-taunus.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-vgo {
  @include brand-home($color-lno-vgo-red);
  #logo {
    background: url('../Images/logo-lno-vgo.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-vht {
  @include brand-home($color-lno-vht-red);
  #logo {
    background: url('../Images/logo-lno-vht.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-rtv {
  @include brand-home($color-lno-rtv-green);
  #logo {
    background: url('../Images/logo-lno-rtv.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-lnvg {
  @include brand-home($color-lno-lnvg-red);
  #logo {
    background: url('../Images/logo-lno-lnvg.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-dadina {
    #logo {
    background: url('../Images/logo-lno-dadina.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-vldw {
  @include brand-home($color-lno-vldw-blue);
  #logo {
    background: url('../Images/logo-lno-vldw.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-frankfurt {
  #logo {
    background: url('../Images/logo-lno-frankfurt.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-marburg-biedenkopf {
  #logo {
    background: url('../Images/logo-lno-marburg-biedenkopf.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-eswe {
  @include brand-home($color-lno-eswe-blue);
  #logo {
    background: url('../Images/logo-lno-eswe.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-vgf {
  @include brand-home($color-lno-vgf-green);
  #logo {
    background: url('../Images/logo-lno-frankfurt.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-wetzlar {
  @include brand-home($color-lno-wetzlar-red);
  #logo {
    background: url('../Images/logo-lno-wetzlar.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-main-kinzig {
  @include brand-home($color-lno-main-kinzig-blue);
  #logo {
    background: url('../Images/logo-lno-main-kinzig.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-kvgof {
  #logo {
    background: url('../Images/logo-lno-kvgof.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;

      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-fulda {
  #logo {
    background: url('../Images/logo-lno-fulda.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}

body.lno-ruesselsheim {
  #logo {
    background: url('../Images/logo-lno-ruesselsheim.svg') 15px 30px no-repeat;
    a.lno-reset {
      width: 220px;
    }
  }
  @media screen and (max-width: $screen-md-max) {
    #logo {
      background-position: 10px 10px;
      background-size: auto 25px;
      a.lno-reset {
        width: 130px;
      }
    }
  }
}


// hide lno-reset link on rmv-logo if get-param caged_lno=1 is set
body.caged-lno {
  #logo {
    a.lno-reset {
      display: none;
    }
  }
}

// Darksite
body.frontend div#page.layout-1 {
  background-image: linear-gradient(to bottom, $color-darkside-lightgray, #fff 360px);
  // fallback if any element is not styled explicit
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  header {
    #logo {
      background: url('../Images/logo-rmv-darksite.png') 15px top no-repeat;
    }
    .UserMenu {
      a:hover, a:focus {
        color: #000;
      }
    }
    .RoutePlanner,
    .OnlineBanner,
    .MeinRmvLogin {
      h1, h2, h3, h4, h5, h6,
      label {
        color: #000;
      }
      .btn-primary,
      .btn-rmv-primary,
      .btn-rmv-secondary {
        background: $color-darkside-darkgray !important;
      }
    }
    .RoutePlanner {
      background: $color-darkside-lightgray;
      .toolbox li a {
        color: $color-darkside-darkgray;
        background: #fff;
        border-radius: 0px 0px 30px 0px;
        &.on {
          color: #fff;
          background: $color-darkside-darkgray;
        }
      }
      img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
      ul li.last a:hover {
        background: #000;
      }
    }
    .OnlineBanner {
      background: $color-darkside-darkgray;
      background-image: none !important;
      h1, h2, h3 ,h4 , h5, h6,
      a, p {
        color: #fff;
      }
      h1 {
        font-size: 40px;
        font-weight: bold;
      }

    }
    .MeinRmvLogin {
      background: $color-darkside-lightgray;
    }
    .login-button i.fa-sign-in {
      color: $color-darkside-darkgray !important;
    }
    .navbar-main {
      a {
        color: $color-darkside-darkgray;
        &.active,
        &:hover,
        &:focus {
          color: #000 !important;
          border-color: #000;
        }
      }
      .dropdown .dropdown-menu {
        h3 {
          color: $color-darkside-darkgray;
        }
        a:not(.btn):hover {
          color: #fff !important;
          background: #000;
        }
      }
    }
  }
  .ServiceBar {
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
  .panel .panel-heading a {
    background: $color-darkside-darkgray !important;
    &.collapsed,
    &.collapsed i.fa {
      background: #fff !important;
      color: $color-darkside-darkgray;
    }
  }
  .TrafficNews {
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
    a:hover, a:focus {
      background: #000;
      color: #fff;
    }
  }
  main {
    .background {
      background: $color-darkside-lightgray;
      h2,
      h2 a,
      h2 a:hover,
      h2 a:focus {
        color: #000;
      }
      .box.has-headline h4 {
        background: url('../Images/box-headline-darkside.png') right top no-repeat;
      }
      .nav-sidebar {
        h3 {
          color: #000;
        }
        a:hover, a.current {
          background: #000;
        }
      }
      .panel .panel-heading a {
        background: #000;
        &.collapsed {
          background: #fff;
        }
      }
      .LinkCollection {
        a.active, a:hover {
          color: #000;
        }
      }
      .btn-primary {
        background: $color-darkside-darkgray !important;
      }
    }
  }

  footer {
    h1, h2, h3, h4, h5, h6,
    a, a:hover, a:focus {
      color: #000;
    }
  }
}

body.frontend.accessibility main {
  p, a, li {
    font-size: 18px;
  }
}

// Hide specific elements (used for integration in the rmv-app without header and footer elements, for eg)
body.only-content {
  header {
    display: none;
  }

  .background {
    padding:  22px;
    .breadcrumb-section {
      display: none;
    }
  }

  footer {
    display: none;
  }
}

// channel control
body.in-app {
  .#{$classname-channel-not-in-app} {
    display: none;
  }
}

body:not(.in-app) {
  .#{$classname-channel-only-in-app} {
    display: none;
  }
}

// this works because the burger menu (div#sidemenu) is outside of div#page
#page {
  .#{$classname-channel-only-in-burger-menu} {
    display: none;
  }
}

.box.mein-rmv-visual {
  background: bottom url(../Images/box-meinrmv-bg.jpg) no-repeat; background-size: cover;
  @media screen and (min-width: $screen-lg) {
    &.has-headline h4 {margin: 0 0 0 367px; }
  }
  @media screen and (max-width: $screen-md) {
    background-image: url(../Images/box-meinrmv-bg-clean.jpg);
  }

  .btn-rmv-primary { margin: 25px 0;
    @media screen and (max-width: 470px) {
      display: block; text-align: center; margin: 20px 0 35px 0;
    }
  }

  p {font-size: 14px; }
  .box-items {
    @media screen and (max-width: 370px) {
      [class*="col-"] { width: 100%; }
    }
    .box-item { background: #fff; height: 100%; margin-bottom: 22px; padding: 15px 10px; box-shadow: 0 0 5px -2px rgba(41, 50, 50, 0.75);
      img.icon {height: 55px; margin: 0 0 20px 0;}
      p.headline {font-size: 13px; font-weight: bold;
        @media screen and (max-width: $screen-md) {
          font-size: 12px;
        }
      }

      p.description {font-size: 12px;}
    }
  }
}

*:focus {
  outline-color: initial !important;
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-offset: -2px !important;
}

// Styling for usage inside the rmv app 3.0
body.layout-inapp {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #162A6B;
  background: #fff;
  @media screen and (min-width: $screen-lg) {
    header {
      .hamburger-icon {
        padding: 15px;
      }
    }
  }
  main {
    h1, h2, h3 {
      line-height: 24px;
      margin: 0px 0px 16px;
    }
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    a {
      font-weight: bold;
      color: #162A6B;
    }
    p {
      margin: 0px 0px 16px;
    }
    figcaption {
      padding: 0px 20px;
      p {
        margin: 0;
      }
    }
    .container {
      background: transparent;
    }
    .background {
      padding: 20px 20px 0;
      background: transparent;
      img {
        border-radius: 16px;
      }
      .box {
        padding: 0;
        margin: 0;
        background: transparent;
        box-shadow: none;
      }
    }
    // Teaser
    .box.has-headline {
      border-radius: 16px;
      background: #EFF7FC;
      > figure {
        margin-left: 22px;
        margin-right: 22px;
      }
      h4 {
        display: none;
      }
      margin-bottom: 22px;
      .row-height.has-box {
        margin-bottom: 0;
      }
    }
    // Accordion
    .panel {
      border: 2px solid #EFF7FC;
      border-radius: 16px;
      background: #EFF7FC;
      .panel-heading {
        border-bottom: transparent;
        background: transparent;
        a {
          background: transparent;
          color: #162A6B;
          i.fa {
            float: right;
            color: #162A6B;
            &:before {
              content: $fa-var-caret-up;
            }
          }
          &.collapsed {
            background: #fff;
            border-radius: 16px;
            i.fa:before {
              content: $fa-var-caret-down;
            }
          }
        }
      }
    }
    // ServiceBar
    .ServiceBar {
      position: relative;
      padding: 24px;
      color: #162A6B;
      border: 2px solid #EFF7FC;
      border-radius: 16px;
      .pull-left:not(.icon-phone) {
        width: calc(100% - 92px);
      }
      .icon-phone {
        margin: auto;
        position: absolute;
        top: 0;
        right: 24px;
        bottom: 0;
        height: 44px;
        width: 44px;
        content: url('../Icons/InApp/Button-Icon.svg');
        img {
          display: none;
        }
      }
      .phone {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
      }
      .servicetimes {
        font-size: 12px;
        line-height: 20px;
      }
      .social-icon {
        display: none;
      }
    }
    .dimmer-bottom {
      height: 70px;
      background: linear-gradient(180deg, rgba(32, 57, 70, 0) 0%, #20398A 100%);
      opacity: 0.12;
    }
  }
}

// Fullwidh-Layout
div#page.tpl-fullwidth,
div#page.tpl-framehafasfullwidth {
  header {
    .header-content {
      display: none;
    }
    .hamburger-icon {
      display: block !important;
    }
    .language-and-search {
      display: none !important;
    }
    .navbar-wrap {
      display: none;
    }
  }
  footer {
    display: none;
  }
}

// In-App v2 layout (only used for cms-content within the old app during the phase where the old app is still available - can be removed afterwards)
body.in-app-2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  background: #fff;
  main {
    h1, h2, h3 {
      line-height: 24px;
      margin: 0px 0px 16px;
    }

    h1 {
      font-size: 22px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    a {
      font-weight: bold;
      color: #162A6B;
    }
    p {
      margin: 0px 0px 16px;
    }
    .background {
      background: #fff;
    }
    .btn.btn-primary {
      width: 100%;
      height: auto;
      padding: 5px 0px;
    }
  }
}
