
$grid-gutter-width:         22px;

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;

$font-family-sans-serif:        arial, helvetica, sans-serif;

$brand-primary:                 $color-rmv-green;

$link-color:            #000;
$link-hover-color:      $link-color;

$font-size-h1:                  24px;
$font-size-h2:                  22px;
$font-size-h3:                  14px;
$font-size-h4:                  13px;
$font-size-h5:                  12px;

$hr-border:                     $color-rmv-middlegray;

$navbar-default-bg:                 #fff;
$navbar-margin-bottom:              0px;
$navbar-default-link-color:         $color-rmv-blue;
$navbar-default-link-active-color:  $color-rmv-blue;
$navbar-default-link-active-bg:     #fff;

$breadcrumb-padding-horizontal: 11px;
$breadcrumb-padding-vertical:   15px;
$breadcrumb-bg:                 transparent;
$breadcrumb-color:              #000000;
$breadcrumb-active-color:       #000000;

$breadcrumb-divider-color:      #000000;
$breadcrumb-separator:          ">";

// Labels
$label-default-bg:            #818a91;
$label-primary-bg:            #0275d8;
$label-success-bg:            #5cb85c;
$label-info-bg:               #5bc0de;
$label-warning-bg:            #f0ad4e;
$label-danger-bg:             #d9534f;

$label-color:                 #fff;
$label-link-hover-color:      #fff;
$label-font-weight:           bold;

// Bootstrap Glyphicons
$icon-font-path: "../Fonts/";

// Fontawesome
$fa-font-path:                "../Fonts/FontAwesome" !default;

// Google Fonts
$google-font-path:            "../Fonts/Googlefonts" !default;

// Animated hamburger icons
$hamburger-padding-x:     10px;
$hamburger-padding-y:     10px;
$hamburger-layer-color:   $color-rmv-green;
$hamburger-hover-opacity: 1;

// Datepicker
$bs-datetimepicker-active-bg: $color-rmv-green;
$bs-datetimepicker-active-color: #fff;

// Pagination
$pagination-border: $color-rmv-middlegray;
$pagination-active-bg: $color-rmv-green;
$pagination-active-border: $color-rmv-middlegray;

// Accordion / Panel
$panel-default-heading-bg: #fff;
$panel-heading-padding: 0;

// Channel control
$classname-channel-all: "channel-0";
$classname-channel-not-in-app: "channel-1";
$classname-channel-only-in-app: "channel-2";
$classname-channel-only-in-burger-menu: "channel-3";

// Form states and alerts
$state-info-bg:     #EFF7FC;
$state-info-text:   #162A6B;
$state-info-border: #EFF7FC;

$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;
$screen-sm-min:              $screen-sm !default;
$screen-md-min:              $screen-md !default;
$screen-lg-min:              $screen-lg !default;
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
}
@media (max-width: $screen-xs-max) {
  @include responsive-invisibility('.hidden-xs');
}
@media (min-width: $screen-xs-max) {
  @include responsive-invisibility('.visible-xs');
}
@media (min-width: $screen-lg-min) {
  @include responsive-invisibility('.hidden-lg');
}
@media (max-width: $screen-md-max) {
  @include responsive-invisibility('.visible-lg');
}

.hidden {
  display: none !important;
}

.btn-block {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
}

.form-group {
  display: flex;
  margin-bottom: 15px;
}

.form-horizontal {
  [class^="col-"] {
    padding-right: 11px;
    padding-left: 11px;
  }
  .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}
#header {
  // main navigation
  nav.navbar {
    padding: 0;
    .navbar-nav {
      display: block;
      > li {
        float: left;
        &.open > a {
          position: relative;
        }
        .dropdown-menu {
          position: absolute;
        }
      }
      @media (min-width: 1200px) {
        .container {
          width: 1320px;
        }
      }
    }
  }
  .RoutePlanner {
    position: relative;
  }
}

// language selector
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: nowrap;
  text-decoration: none;
}

// used in mm-menu as mobile footer
.dropup .dropdown-menu {
  top: auto;
  bottom: 22px;
}

main {
  .nav-pills {
    display: block;
    .nav {
      display: block;
    }
  }
}

.btn-default {
  border-color: #ccc !important;
}

.Accordion {
  .panel-default {
    background: #fff;
  }
  .panel-body {
    padding: 15px;
  }
  // is .show in bootstrap 5
  .collapse:not(.in) {
    display: none;
  }

  .collapse.in {
    display: block;
  }

}

#footer {
  .nav-pills {
    display: block;
  }
  .list-inline {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
}